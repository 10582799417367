import { NavLink } from "react-router-dom";
import Container from "./Container";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const menuItems = [
    { to: "/", menuName: "Home" },
    { to: "/contact", menuName: "Contact" },
    // Add more menu items as needed
  ];

  return (
    <footer className="bg-black mt-10 border-t border-[#535353]">
      <Container>
        <div className="py-4">
          <ul className="flex justify-center items-center space-x-6">
            {menuItems.map((item, index) => (
              <li key={index}>
                <NavLink
                  to={item.to}
                  className="text-white hover:text-gray-300 transition duration-300"
                  style={({ isActive }) => ({
                    color: isActive ? "#EF0101" : "#FFFFFF",
                    cursor: isActive ? "default" : "pointer",
                  })}
                >
                  {item.menuName}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </Container>

      <div className="bg-red-light py-2">
        <Container>
          <div className="text-[10px] text-center">
           This website is a fan project and is not affiliated with or endorsed by Forbidden SIREN.
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
